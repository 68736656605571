<template>
  <div id="vatTax" style="width:100%;height:250px;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";

let resizeFunc

export default {
  name: "vatTax",
  props: {
    activeIndex: String,
  },
  data() {
    return {
      list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      myChart: null,
    };
  },
  watch: {
    activeIndex() {
      this.$nextTick(()=>{
        this.myChart?.resize();
      })
      
    },
  },
  mounted() {
    this.initChart(this.list);
  },
  beforeUnmount() {
    window.removeEventListener('resize', resizeFunc)
  },
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    getResizeFunc(chartInstance){ 
      resizeFunc = () => {
        chartInstance?.resize()
      }
    },
    initChart(list) {
      var myChart = echarts.init(document.getElementById("vatTax"));
      this.myChart = myChart
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list,
            type: "line",
            smooth: true,
            areaStyle: {},
            name: "金额",
            color: "#17a2b8",
          },
        ],
      });
      this.getResizeFunc(myChart)
      window.addEventListener('resize', resizeFunc)
    },
  },
};
</script>
