<template>
  <div id="outPutSum" style="width:577px;height:224px;;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';
export default {
  name:'outPutSum',
  props:{

  },
  data() {
    return {

    }
  },
  created(){
  },
  methods:{
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    initChart(list){
      let seriesData = list.map(v=>{
        return {
          value: v.count,
          name: v.type,
          amount:this.$comdify(v.amount),
        }
      })
      var myChart = echarts.init(document.getElementById('outPutSum'));
      myChart.setOption({
        // title: {
        //   text: '到票统计',
        //   left: 'center'
        // },
        tooltip: {
          trigger: 'item',
          formatter:function(params){
            return `类型：${params.name}<br>张数：${params.value}<br>合计：${params.data.amount}`
          }
        },
        legend: {
          // orient: 'vertical',
          x: 'left'
        },
        series: [ 
          {
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              position: 'center',
              formatter: '{b}:{c}' + '\n\r' + '({d}%)',
              normal:{
              }
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '15',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: seriesData
          }
        ]
      })
      window.onresize = function(){
        myChart.resize()
      }
    }
  }
}
</script>