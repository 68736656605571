<template>
  <div id="bankSum" style="width:100%;height:245px;;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";
export default {
  name: "bankSum",
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    initChart(list) {
      let arr = list.map((v) => {
        return {
          value: v.amount,
          name: v.type,
        };
      }); //判断显示值
      let seriesData = arr.map((item) => {
        let changeItem = {};
        this.deepCopy(changeItem, item);
        return changeItem;
      }); //渲染值
      seriesData.map((item) => {
        for (let key in item) {
          if (item[key] < 0) {
            item[key] = JSON.stringify(Math.abs(item[key]));
          }
        }
      });
      var myChart = echarts.init(document.getElementById("bankSum"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function(p) {
            let value =
              arr[p.dataIndex].value * 1 > 0 ? p.value * 1 : p.value * -1;
            let name = arr[p.dataIndex].name;
            let str = name + "：" + value;
            return str;
          },
        },
        legend: {
          orient: "vertical",
          x: "left",
          type: "scroll",
          formatter: (name) => {
            if (!name) return "";
            if (name.length > 7) {
              name = name.slice(0, 7) + "...";
            }
            return name;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            center: ["65%", "50%"],
            label: {
              position: "center",
              //formatter: "{b}:{c}" + "\n\r" + "({d}%)",
              normal: {
                formatter: function(param) {
                  let name = param.name;
                  if (name.length <= 7) {
                    return name;
                  } else {
                    return name.slice(0, 7) + "...";
                  }
                },
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: seriesData,
          },
        ],
      });
      window.onresize = function() {
        myChart.resize();
      };
    },
    deepCopy(newobj, oldobj) {
      for (let k in oldobj) {
        let item = oldobj[k];
        if (item instanceof Array) {
          newobj[k] = [];
          deepCopy(newobj[k], item);
        } else if (item instanceof Object) {
          newobj[k] = {};
          deepCopy(newobj[k], item);
        } else {
          newobj[k] = item;
        }
      }
    },
  },
};
</script>
