<template>
  <div id="qysdsTax" style="width:100%;height:250px;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";

let resizeFunc

export default {
  name: "qysdsTax",
  props: {
    activeIndex: String,
  },
  data() {
    return {
      list: [0, 0, 0, 0],
      myChart: null,
    };
  },
  mounted() {
    // this.initChart(this.list);
  },
  beforeUnmount() {
    window.removeEventListener('resize', resizeFunc)
  },
  watch: {
    activeIndex() {
      this.$nextTick(()=>{
        this.myChart?.resize();
      })
      
    },
  },
  methods: {
    init(list) {
      //console.log(list);
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    getResizeFunc(chartInstance){ 
      resizeFunc = () => {
        chartInstance?.resize()
      }
    },
    initChart(list) {
      const myChart = echarts.init(document.getElementById("qysdsTax"));
      this.myChart = myChart
      myChart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: ["第一季度", "第二季度", "第三季度", "第四季度"],
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list,
            type: "bar",
            barWidth: 30,
            name: "金额",
            color: "#17a2b8",
          },
        ],
      });
      this.getResizeFunc(myChart)
      window.addEventListener('resize', resizeFunc)
    },
  },
};
</script>
