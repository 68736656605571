<template>
  <div id="rightAllTotal" style="width:100%;height:330px;border: 1px solid #d7d7d7;padding: 10px;" :style="{height:contentStyleObj}"></div>
</template>

<script type="text/javascript">
import * as echarts from 'echarts';

let resizeFunc

export default {
  name:'rightAllTotal',
  props:{

  },
  data() {
    return {
      contentStyleObj:{},

    }
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(450)
  },
  beforeUnmount() {
    window.removeEventListener('resize', resizeFunc)
  },
  methods:{
    init(list){
      this.$nextTick(() => {
        this.initChart(list)
      })
    },
    getResizeFunc(chartInstance){ 
      resizeFunc = () => {
        chartInstance.resize()
      }
    },
    initChart(list){
      var myChart = echarts.init(document.getElementById('rightAllTotal'));
      myChart.setOption({
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["收入", "成本", "费用", "税金", "利润"],
          x: 'left'
        },
        grid: {
          left: "0",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月"
          ]
        },
        yAxis: {
          type: "value"
        },
        series: [ 
          {
            name: "收入",
            type: "line",
            smooth: true,
            data: list.income
          },
          {
            name: "成本",
            type: "line",
            smooth: true,
            data: list.cost
          },
          {
            name: "费用",
            type: "line",
            smooth: true,
            data: list.fee
          },
          {
            name: "税金",
            type: "line",
            data: list.tax
          },
          {
            name: "利润",
            type: "line",
            smooth: true,
            data: list.profit
          }
        ]
      })
      this.getResizeFunc(myChart)
      window.addEventListener('resize', resizeFunc)
    }
  }
}
</script>

<style>

</style>