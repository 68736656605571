<template>
  <div id="taxSum" style="width:577px;height:245px;display:inline-block"></div>
</template>

<script type="text/javascript">
import * as echarts from "echarts";

let resizeFunc

export default {
  name: "taxSum",
  props: {},
  data() {
    return {};
  },
  created() {},
  beforeUnmount() {
    window.removeEventListener('resize', resizeFunc)
  },
  methods: {
    init(list) {
      this.$nextTick(() => {
        this.initChart(list);
      });
    },
    getResizeFunc(chartInstance){ 
      resizeFunc = () => {
        chartInstance?.resize()
      }
    },
    initChart(list) {
      let seriesData = Object.entries(list).map(([name, value]) => ({name,value}))
      var myChart = echarts.init(document.getElementById("taxSum"));
      myChart.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          x: "left",
          type: "scroll",
          formatter: (name) => {
            if (!name) return "";
            if (name.length > 8) {
              name = name.slice(0, 8) + "...";
            }
            return name;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["60%", "80%"],
            center: ["60%", "50%"],
            avoidLabelOverlap: true,
            label: {
              position: "center",
              formatter: "{b}:{c}" + "\n\r" + "({d}%)",
              normal: {},
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "15",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: seriesData,
          },
        ],
      });
      this.getResizeFunc(myChart)
      window.addEventListener('resize', resizeFunc)
    },
  },
};
</script>
